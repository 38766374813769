import { render, staticRenderFns } from "./detail.vue?vue&type=template&id=24887885&scoped=true&"
import script from "./detail.vue?vue&type=script&lang=js&"
export * from "./detail.vue?vue&type=script&lang=js&"
import style0 from "./detail.vue?vue&type=style&index=0&id=24887885&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24887885",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CSpinner: require('@chakra-ui/vue').CSpinner, CText: require('@chakra-ui/vue').CText, CFlex: require('@chakra-ui/vue').CFlex, CBox: require('@chakra-ui/vue').CBox, CButton: require('@chakra-ui/vue').CButton, CImage: require('@chakra-ui/vue').CImage, CGrid: require('@chakra-ui/vue').CGrid, CIcon: require('@chakra-ui/vue').CIcon, CDrawerOverlay: require('@chakra-ui/vue').CDrawerOverlay, CDrawerHeader: require('@chakra-ui/vue').CDrawerHeader, CDrawerBody: require('@chakra-ui/vue').CDrawerBody, CDrawerContent: require('@chakra-ui/vue').CDrawerContent, CDrawer: require('@chakra-ui/vue').CDrawer})
